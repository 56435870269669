/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;600&family=Roboto:wght@100&display=swap');


:root {

    --primary-clr: #20212c;
    --primary-nav-bg: #2b2c37;

    --green-row-icon: white;
    --green-row-hover: #325333;
    --blue-row: #4C5F7A;
    --blue-row-hover: #43536e;
    --version-table-header: #29435C;
    --green-row: #467047FF;
    --orange-row: #af7105;
    --page-title-clr: #ffffff;
    --item-bg-color: #635fc7;
    --deativated-bg: #979797;
    --button-color: #ffffff;

    --text-color: #f4f7fd;

    --nav-text-color: #d9d9d9;
    --nav-text-color-active: #FFFFFF;

    --desktop-hidden-button: #A8A4FF;

    --font-family: "Plus Jakarta Sans", sans-serif;

    --material-hover: rgb(32, 32, 43);


    --size-100: 0.5rem;
    --size-200: 0.775rem;
    --size-300: 0.975rem;
    --size-400: 1rem;
    --size-500: 1.25rem;
    --size-600: 1.5rem;
    --size-700: 2rem;
    --size-800: 2.5rem;
    --size-900: 3rem;

    --fs-xl: 24px;
    --fs-l: 18px;
    --fs-m: 15px;
    --fs-s: 12px;

    --main-display-inline-margin: 20px auto;
}

html,
body {
    margin: 0;
    padding: 0;
    font-family: var(--font-family);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
    margin: 0;
    padding: 0;
    font: inherit;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
    margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
    list-style: none;
}

/* Set core root defaults */
html:focus-within {
    scroll-behavior: smooth;
}

html {
    overflow-y: auto;
}

/* Set core body defaults */
body {
    min-height: 100vh;
    line-height: 1.5;
}

.table {
    margin: 0;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
    text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
    max-width: 100%;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
    html:focus-within {
        scroll-behavior: auto;
    }

    *,
    *::before,
    *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

nav ul {
    padding: 0;
    margin: 0;
}

/* UTILITY CLASSES */

.bg-primary-clr {
    background-color: var(--primary-clr);
}

.bg-secondary-clr {
    background-color: var(--primary-nav-bg);
}

.primary-heading {
    color: var(--page-title-clr);
    font-size: var(--size-600);
}

.text-center {
    text-align: center;
}

.text-color {
    color: var(--text-color);
}

.button {
    cursor: pointer;
    border: 0;
    /*background-color: var(--item-bg-color);*/
    background: linear-gradient(to right, #9e77e5, #7194e6);
    border-radius: 100vmax;
    padding: 0.5em var(--size-500);
    font-size: var(--size-300);
    display: inline-flex;
    align-items: center;
    color: var(--button-color);
}

.button:hover {
    background-color: var(--desktop-hidden-button);
    transition: background-color 0.2s ease-in;
}


:root:has(#light:checked) {
    --primary-clr: #f4f7fd;
    --primary-nav-bg: #ffffff;
    --orange-row: #f8c074;
    --green-row: #bbf8bb;
    --green-row-hover: #88cd88;
    --blue-row: #CEE6F3;
    --blue-row-hover: #a4cfec;
    --version-table-header: #5F85DB;
    --green-row-icon: #635fc7;

    --text-color: #20212c;
    --nav-text-color: #828fa3;
    --material-hover: rgb(237, 238, 243);
}


/* NGX TOASTR */


.toast-center-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.toast-top-center {
    top: 20px !important;
    right: 0;
    width: 100%;
}

.toast-bottom-center {
    bottom: 0;
    right: 0;
    width: 100%;
}

.toast-top-full-width {
    top: 0;
    right: 0;
    width: 100%;
}

.toast-bottom-full-width {
    bottom: 0;
    right: 0;
    width: 100%;
}

.toast-top-left {
    top: 12px;
    left: 12px;
}

.toast-top-right {
    top: 12px;
    right: 12px;
}

.toast-bottom-right {
    right: 12px;
    bottom: 12px;
}

.toast-bottom-left {
    bottom: 12px;
    left: 12px;
}

/* toast styles */
.toast-title {
    font-weight: bold;
}

.toast-message {
    word-wrap: break-word;
}

.toast-message a,
.toast-message label {
    color: #FFFFFF;
}

.toast-message a:hover {
    color: #CCCCCC;
    text-decoration: none;
}

.toast-close-button {
    position: relative;
    right: -0.3em;
    top: -0.3em;
    float: right;
    font-size: 20px;
    font-weight: bold;
    color: #FFFFFF;
    text-shadow: 0 1px 0 #ffffff;
    /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
    color: #000000;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
}

.toast-container {
    pointer-events: none;
    position: fixed;
    z-index: 999999;
}

.toast-container * {
    box-sizing: border-box;
}

.toast-container .ngx-toastr {
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 15px 15px 15px 50px;
    width: 300px;
    border-radius: 3px 3px 3px 3px;
    background-position: 15px center;
    background-repeat: no-repeat;
    background-size: 24px;
    box-shadow: 0 0 0 0 !important;
    color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
    box-shadow: 0 0 12px #000000 !important;
    opacity: 1;
    cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
    width: 96%;
    margin-left: auto;
    margin-right: auto;
}

.ngx-toastr {
    background-color: #030303;
    pointer-events: auto;
}

.toast-success {
    background-color: #51A351;
}

.toast-error {
    background-color: #BD362F;
}

.toast-info {
    background-color: #2F96B4;
}

.toast-warning {
    background-color: #F89406;
}

.toast-progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    background-color: #000000;
    opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
    .toast-container .ngx-toastr.div {
        padding: 8px 8px 8px 50px;
        width: 11em;
    }

    .toast-container .toast-close-button {
        right: -0.2em;
        top: -0.2em;
    }
}

@media all and (min-width: 241px) and (max-width: 480px) {
    .toast-container .ngx-toastr.div {
        padding: 8px 8px 8px 50px;
        width: 18em;
    }

    .toast-container .toast-close-button {
        right: -0.2em;
        top: -0.2em;
    }
}

@media all and (min-width: 481px) and (max-width: 768px) {
    .toast-container .ngx-toastr.div {
        padding: 15px 15px 15px 50px;
        width: 25em;
    }
}

html, body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    display: none;
}

::-webkit-scrollbar-corner {
    display: none;
}

::-webkit-scrollbar-thumb {
    background: var(--item-bg-color);
    outline: none;
}

.error {
    position: absolute;
    max-width: fit-content;
    text-align: left;
    font-size: 12px;
    color: red !important;
    animation: error-message 0.2s linear;
}

@keyframes error-message {
    from {
        opacity: 0;
        transform: translateY(-25px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }

}
